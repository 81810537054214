import { post } from '@/utils/request';
import Info from './info.vue';
export default {
  components: {
    Info
  },
  data() {
    return {
      module: 'flow',
      actions: {
        tabs: [{
          label: '全部',
          name: '-1'
        }, {
          label: '待审批',
          name: '0'
        }, {
          label: '已审批',
          name: '1'
        }, {
          label: '未通过',
          name: '2'
        }],
        columns: '/flow/columns',
        index: '/flow/index'
      },
      operates: {
        add: false,
        edit: false,
        del: false,
        other: [{
          show: this.isShowButton,
          title: '通过',
          type: 'primary',
          click: this.examinePass
        }, {
          show: this.isShowButton,
          title: '驳回',
          type: 'danger',
          click: this.examineFlase
        }]
      }
    };
  },
  methods: {
    tableGetList() {
      this.$refs.tableRef.getList();
    },
    isShowButton(row) {
      return row.status === 0;
    },
    openInfo(row) {
      this.$refs.infoRef.open(row);
    },
    async examinePass(row) {
      await this.$confirm('确认审核通过？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await post('/flow/examine', {
        id: row.id,
        status: 1
      });
      this.tableGetList();
      this.$refs.infoRef.toggleVisible(false);
    },
    async examineFlase(row) {
      const {
        value
      } = await this.$prompt('请填写驳回原因', '提示', {
        confirmButtonText: '驳回',
        cancelButtonText: '取消',
        type: 'warning',
        inputValidator: v => !!v && !!v.trim() || '驳回原因不能为空',
        inputErrorMessage: '驳回原因不能为空'
      });
      await post('/flow/examine', {
        id: row.id,
        status: 2,
        reason: value
      });
      this.tableGetList();
      this.$refs.infoRef.toggleVisible(false);
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-drawer", {
    attrs: {
      title: _vm.row.title,
      size: 700
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_vm._t("footer", null, {
          row: _vm.row
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "dp-f align-items-center item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("发起人：")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.row.admin_id_replace))])]), _c("div", {
    staticClass: "dp-f align-items-center item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("类型：")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.row.type_replace))])]), _c("div", {
    staticClass: "dp-f align-items-center item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("状态：")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.row.status_replace))])]), _vm.row.reason ? _c("div", {
    staticClass: "dp-f align-items-center item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("驳回原因：")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.row.reason))])]) : _vm._e(), _vm._l(_vm.row.show_data, function (v, k) {
    return [k === "合同编号" ? _c("div", {
      staticClass: "dp-f align-items-center item"
    }, [_c("span", {
      staticClass: "label"
    }, [_vm._v(_vm._s(k) + "：")]), _c("span", {
      staticClass: "content"
    }, [_c("w-pdf", {
      attrs: {
        buttonText: v.contract_code,
        url: _vm.makeUrl(v.contract)
      }
    })], 1)]) : k === "合同公司" ? _c("div", {
      staticClass: "dp-f align-items-center item"
    }, [_c("span", {
      staticClass: "label"
    }, [_vm._v(_vm._s(k) + "：")]), _c("span", {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(v.title || v) + " ")])]) : ["string", "number"].includes(typeof v) ? _c("div", {
      staticClass: "dp-f align-items-center item"
    }, [_c("span", {
      staticClass: "label"
    }, [_vm._v(_vm._s(k) + "：")]), _c("span", {
      staticClass: "content",
      domProps: {
        innerHTML: _vm._s(v)
      }
    })]) : [_c("div", {
      staticClass: "label"
    }, [_vm._v(_vm._s(k) + "：")]), v.length ? _c("el-table", {
      attrs: {
        data: v,
        stripe: "",
        border: "",
        size: "mini"
      }
    }, _vm._l(_vm.makeColumns(v), function (item) {
      return _c("el-table-column", _vm._b({
        key: item.prop,
        scopedSlots: _vm._u([{
          key: "default",
          fn: function ({
            row
          }) {
            return [_c("span", {
              domProps: {
                innerHTML: _vm._s(row[item.prop])
              }
            })];
          }
        }], null, true)
      }, "el-table-column", item, false));
    }), 1) : _vm._e()]];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
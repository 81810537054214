var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p15 w-main-content dp-f f-d-c h100"
  }, [_c("w-tabs-table", {
    ref: "tableRef",
    attrs: {
      actions: _vm.actions,
      operates: _vm.operates
    },
    on: {
      look: _vm.openInfo
    }
  }), _c("Info", {
    ref: "infoRef",
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function ({
        row
      }) {
        return [row && _vm.isShowButton(row) ? [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.examinePass(row);
            }
          }
        }, [_vm._v("通过")]), _c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.examineFlase(row);
            }
          }
        }, [_vm._v("驳回")])] : _vm._e()];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
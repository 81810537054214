import { queryToObj, objToQuery } from '@/utils/common';
import { getToken } from '@/utils/auth';
export default {
  data() {
    return {
      visible: false,
      row: {}
    };
  },
  methods: {
    makeColumns(list = []) {
      return list.length ? Object.keys(list[0]).map(k => ({
        label: k,
        prop: k
        // align: 'center',
      })) : [];
    },
    toggleVisible(visible) {
      this.visible = visible === undefined ? !this.visible : visible;
    },
    open(row) {
      row = JSON.parse(JSON.stringify(row));
      this.toggleVisible(true);
      row.show_data = JSON.parse(row.show_data);
      console.log(row.show_data);
      this.row = row;
    },
    makeUrl(v = '') {
      const [path, query] = v.split('?');
      const obj = queryToObj(query);
      obj.Authorization = getToken();
      return path + objToQuery(obj);
    }
  }
};